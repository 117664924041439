// fcm.service.ts

import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FcmService {
  currentMessage = new BehaviorSubject<any>(null);
  private tokenSubject = new BehaviorSubject<string | null>(null);

  constructor(private afMessaging: AngularFireMessaging) {
    this.afMessaging.messages.subscribe((message) => {
      this.currentMessage.next(message);
    });

    this.listenToTokenRefresh();
  }

  requestPermission(): Promise<void> {
    return this.afMessaging.requestPermission.pipe(take(1)).toPromise();
  }

  getToken(): Promise<string | null> {
    return this.afMessaging.getToken.pipe(take(1)).toPromise();
  }

  private saveTokenToServer(token: string) {
  }

  listenToTokenRefresh() {
    this.afMessaging.tokenChanges.subscribe(
      (token) => {
        this.tokenSubject.next(token);
        if (token) {
          this.saveTokenToServer(token);
        }
      },
      (error) => {
        console.error('Unable to refresh token', error);
      }
    );
  }

  getCurrentToken() {
    return this.tokenSubject.asObservable();
  }
}
