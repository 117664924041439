import { Component } from '@angular/core';

@Component({
  selector: 'vex-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss']
})
export class ThankyouComponent {

}
