import { Component } from '@angular/core';

@Component({
  selector: 'vex-thank',
  templateUrl: './thank.component.html',
  styleUrls: ['./thank.component.scss']
})
export class ThankComponent {

}
