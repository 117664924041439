/// <reference types="@types/googlemaps" />
import {
  Component,
  ElementRef,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  SecurityContext,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  NgForm,
  ValidationErrors,
  FormArray,
} from "@angular/forms";
import { fadeInUp400ms } from "../../@vex/animations/fade-in-up.animation";
import { stagger60ms } from "../../@vex/animations/stagger.animation";
import {
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
  MatFormFieldDefaultOptions,
} from "@angular/material/form-field";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { AuthService } from "src/app/pages/auth/authServices";
import { Subscription } from "rxjs";
import { Location } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { ToastrService } from "ngx-toastr";
import { environment } from "src/environments/environment";
import { SafeResourceUrl, DomSanitizer } from "@angular/platform-browser";
import { PDFDocument } from "pdf-lib";
import { STATES } from "../pages/state";
import { CalendarService } from "../pages/calendar/calendar.service";

import { DemoService } from "./demo.service";
import { BodyPartsComponent } from "./body-parts/body-parts.component";
export interface PlaceSearchResult {
  address: string;
  imageUrl?: string;
  location?: google.maps.LatLng;
  iconUrl?: string;
  name?: string;
}

@Component({
  selector: "vex-demo",
  templateUrl: "./demo.component.html",
  styleUrls: ["./demo.component.scss"],
  animations: [fadeInUp400ms, stagger60ms],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "standard",
      } as MatFormFieldDefaultOptions,
    },
  ],
})
export class DemoComponent {
  isRecaptchaVerified: boolean = false;
  onRecaptchaResolved(captchaResponse: string): void {
    if (captchaResponse) {
      this.isRecaptchaVerified = true;
    }
  }

  displayedColumns: string[] = ["recipient", "mode", "pdfURL"];
  wcabSelection: string = "None Provided";
  isCustomWCAB: boolean = false;

  claimNumberSelection: string = "None Provided";
  isCustomClaimNumber: boolean = false;
  states = STATES;

  @ViewChild("inputField") inputField!: ElementRef<HTMLInputElement>;
  autocomplete: google.maps.places.Autocomplete | undefined;
  @Output() placeChanged = new EventEmitter<PlaceSearchResult>();
  BaseFileURL = environment.fileURL;
  languages: string[] = [
    "Spanish",
    "Portuguese",
    "English",
    "German",
    "Italian",
    "Arabic",
    "Chinese",
    "Ukrainian",
    "Japanese",
    "Dutch",
    "French",
  ];
  form: FormGroup;
  isDraft: any = "false";
  applicants: any[] = [];
  filteredApplicants: any[] = [];
  attorneys: any[] = [];
  filteredApplicantAttorney: any[] = [];
  insuracneCompany: any[] = [];
  filteredInsurance: any[] = [];

  public mode = "add";
  isSubmitting = false;
  physicians: any[] = [];

  private subscriptions: Subscription[] = [];
  private ID: string;
  private authStatusSub: Subscription;
  isLoading = false;
  imagePreview: string | ArrayBuffer;
  roleSub: Subscription;
  numRegex = /^-?\d*[.,]?\d{0,4}$/;
  @ViewChild("formDirective") private formDirective: NgForm;
  existingStrikePanelFiles: any[] = [];
  existingAttorneyDemoFiles: any[] = [];
  pdfUrl: any | SafeResourceUrl;
  recipients: { name: string; mode: any; pdfURL: SafeResourceUrl }[];
  isSubmitting2: boolean;
  isSubmitting3: boolean;
  startDate: any;
  endDate: any;
  dateTimeRange: any;
  c: any;

  status: any;
  status2: any;
  status3: any;
  status5: any;

  requestingParties: string[] = [
    'Applicant Attorney',
    'Defence Attorney',
    'Applicant',
    'Adjuster',
    'Employer'
  ];
  

  clinics: any;
  status4: string;
  constructor(
    private authService: AuthService,
    public route: ActivatedRoute,
    private location: Location,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private calendarService: CalendarService,
    private demoService: DemoService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.authStatusSub = this.authService
      .getAuthStatusListener()
      .subscribe((authStatus) => {
        this.isLoading = false;
      });

    this.form = new FormGroup({
      applicantSearch: new FormControl(""),
      attorneySearch: new FormControl(""),
      attorneySearch_2: new FormControl(""),
      insuranceSearch_2: new FormControl(""),

      reqFirstName: new FormControl("", [Validators.required]),
      reqMiddleName: new FormControl(""),
      reqLastName: new FormControl("", [Validators.required]),
      reqEmail: new FormControl("", [Validators.required, Validators.email]),
      reqParty: new FormControl("", [Validators.required]),
      reqDateRange_start: new FormControl(""),
      reqDateRange_end: new FormControl(""),
      
      physician: new FormControl("", [Validators.required]),
      panelNumber: new FormControl(""),
      medicalEvaluationType: new FormControl("Qualified Medical Evaluation", [
        Validators.required,
      ]),
      evaluationType: new FormControl("Comprehensive"),
      encounterType: new FormControl("", [Validators.required]),
      // dateOfAppointment: new FormControl("", [Validators.required]),
      // dateOfAppointmentCall: new FormControl("", [Validators.required]),
      online: new FormControl(false),
      meetingLink: new FormControl(""),
      examinationAddress: new FormControl("", [Validators.required, this.noWhitespaceValidator]),
      examinationCity: new FormControl("", [Validators.required, this.noWhitespaceValidator]),
      examinationState: new FormControl("", [Validators.required,this.noWhitespaceValidator]),
      examinationZip: new FormControl("", [Validators.required,this.noWhitespaceValidator]),
      previousEncounter: new FormArray([]),
      strikePanel: new FormControl(null),
      attorneyDemo: new FormControl(null),
      applicantName: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      applicantMiddleName: new FormControl(""),
      applicantLastName: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      dob: new FormControl("", [Validators.required]),
      ssn: new FormControl(""),
      gender: new FormControl(""),
      address: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      city: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      state: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      zip: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      email: new FormControl("", [
        Validators.required,
        Validators.email
      ]),
      phone: new FormControl("", [Validators.required, this.phoneValidator]),
      altPhone: new FormControl("", [this.phoneValidator]),
      jobtitle: new FormControl(""),
      modeOfTransmission: new FormControl("USPS"),
      applicantDigitalService: new FormControl("Not sent"),
      injuryDetails: new FormArray([]),

      interpreter: new FormControl(false),
      language: new FormControl(""),
      vendor: new FormControl(""),
      vendorPhone: new FormControl(""),

      unrepresented: new FormControl(false),
      firm: new FormControl(""),
      applicantAttorneyname: new FormControl(""),
      applicantAttorneyaddress: new FormControl(""),
      applicantAttorneyCity: new FormControl(""),
      applicantAttorneyState: new FormControl(""),
      applicantAttorneyZip: new FormControl(""),
      applicantAttorneyTelePhone: new FormControl(""),
      applicantAttorneyFax: new FormControl(""),
      applicantAttorneyEmail: new FormControl(""),
      applicantAttorneyAssitantEmail: new FormControl(""),
      applicantAttorneymodeOfTransmission: new FormControl("USPS"),
      applicantAttorneyDigitalService: new FormControl("Not sent"),

      defenceFirm: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      defenceName: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      defenceAddress: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      defenceCity: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      defenceState: new FormControl("", [Validators.required]),
      defenceZip: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      defencePhone: new FormControl("", [
        Validators.required,
        this.phoneValidator,
      ]),
      defenceFax: new FormControl(""),
      defenceEmail: new FormControl("", [
        Validators.required,
        Validators.email,
      ]),
      defenceAssistantEmail: new FormControl("", [
        Validators.required,
        Validators.email,
      ]),
      defencemodeOfTransmission: new FormControl("USPS"),
      defenceDigitalService: new FormControl("Not sent"),

      employer: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      employerPhone: new FormControl("", [
        Validators.required,
        this.phoneValidator,
      ]),
      employerFax: new FormControl(""),
      employerEmail: new FormControl("", [
        Validators.required,
        Validators.email,
      ]),
      employerAddress: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      employerCity: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      employerState: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      employerZip: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      employermodeOfTransmission: new FormControl("USPS"),
      employerDigitalService: new FormControl("Not sent"),

      insuranceCompany: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      insuranceCompanyPhone: new FormControl("", [
        Validators.required,
        this.phoneValidator,
      ]),
      payerId: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      // companyPayerId: new FormControl(""),
      insurancemodeOfTransmission: new FormControl("USPS"),
      insuranceDigitalService: new FormControl("Not sent"),
      billingEmail: new FormControl(""),
      billingFax: new FormControl(""),
      billingAddress: new FormControl(""),
      billingReviewPhone: new FormControl(""),
      billingReviewEmail: new FormControl(""),
      adjuster: new FormControl("", [
        Validators.required,
        this.noWhitespaceValidator,
      ]),
      insuranceAddress: new FormControl("", [Validators.required]),
      insuranceCity: new FormControl("", [Validators.required]),
      insuranceState: new FormControl("", [Validators.required]),
      insuranceZip: new FormControl("", [Validators.required]),
      insurancePhone: new FormControl("", [Validators.required, this.phoneValidator]),
      insuranceFax: new FormControl(""),
      insuranceEmail: new FormControl("", [
        Validators.required,
        Validators.email
      ]),
      insuranceAssistant: new FormControl("", [
        Validators.required,
        Validators.email
      ]),
      insuranceClaimNo: new FormArray([]),
    });

    this.fetchPhysicians();


    this.form.get("WCAB")?.setValue(this.wcabSelection);

    this.form.get("claimNumber")?.setValue(this.claimNumberSelection);
  }

  cleanData(data: any): any {
    const cleanedData: any = {};
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        cleanedData[key] = data[key] == null ? "" : data[key];
      }
    }
    return cleanedData;
  }

  //------------- get physician data -----------------//

  fetchPhysicians() {
    const sub = this.demoService.getPhysicians().subscribe((response) => {
      this.physicians = response.data;
    });
    this.subscriptions.push(sub);
    
    this.isLoading = false;
  }

  //------------------------ END ---------------------- //

  onWCABChange(index: number, value: string) {
    if (value === "") {
      this.injuryDetails.at(index).patchValue({ isCustomWCAB: true });
    } else {
      this.injuryDetails
        .at(index)
        .patchValue({ isCustomWCAB: false, WCAB: value });
    }
  }

  onClaimNumberChange(index: number, value: string) {
    if (value === "") {
      this.injuryDetails.at(index).patchValue({ isCustomClaimNumber: true });
    } else {
      this.injuryDetails
        .at(index)
        .patchValue({ isCustomClaimNumber: false, claimNumber: value });
    }
  }

  onBlur(): void {
    if (!this.wcabSelection) {
      this.wcabSelection = "None Provided";
      this.isCustomWCAB = false;
      this.form.get("WCAB")?.setValue(this.wcabSelection);
    }
  }

  onClaimNumberBlur(): void {
    if (!this.claimNumberSelection) {
      this.claimNumberSelection = "None Provided";
      this.isCustomClaimNumber = false;
      this.form.get("claimNumber")?.setValue(this.claimNumberSelection);
    }
  }

  //--------------- insurrance claim ------------------//

  get insuranceClaimNoArray(): FormArray {
    return this.form.get("insuranceClaimNo") as FormArray;
  }

  addClaimNumber(): void {
    this.insuranceClaimNoArray.push(new FormControl(""));
  }

  removeClaimNumber(index: number): void {
    this.insuranceClaimNoArray.removeAt(index);
  }

  //------------------ END ---------------------------//

  //----------------- injuryDetails ------------------//

  createInjuryDetail(): FormGroup {
    return new FormGroup({
      injuryType: new FormControl("specific"),
      specificDate: new FormControl(false),
      start: new FormControl(""),
      end: new FormControl(""),
      injuryBodyPart: new FormControl(""),
      WCAB: new FormControl("None Provided"),
      claimNumber: new FormControl("None Provided"),
      isCustomWCAB: new FormControl(false),
      isCustomClaimNumber: new FormControl(false),
    });
  }

  get injuryDetails(): FormArray {
    return this.form.get("injuryDetails") as FormArray;
  }

  addInjuryDetail() {
    this.injuryDetails.push(this.createInjuryDetail());
  }

  onInjuryTypeChange(index: number) {
    const detail = this.injuryDetails.at(index);
    const injuryType = detail.get("injuryType").value;

    if (injuryType === "specific") {
      detail.get("specificDate")?.setValue(null);
      detail.get("start")?.setValue(null);
      detail.get("end")?.setValue(null);
    } else if (injuryType === "cumulative") {
      detail.get("specificDate")?.setValue(null);
    }
  }

  removeInjuryDetail(index: number) {
    this.injuryDetails.removeAt(index);
  }

  //-------------------- END -------------------//

  // Previous Encounter
  createPreviousDetail(): FormGroup {
    return new FormGroup({
      previousDate: new FormControl(""),
      evaluationType: new FormControl(""),
    });
  }

  get previousEncounter(): FormArray {
    return this.form.get("previousEncounter") as FormArray;
  }

  addPreviousDetail() {
    this.previousEncounter.push(this.createPreviousDetail());
  }

  removePreviousDetail(index: number) {
    this.previousEncounter.removeAt(index);
  }

  //-----------------

  onPhysicianChange(physicianId: string): void {
    if (physicianId) {
      this.fetchPhysicianClinic(physicianId);
    } else {
      this.clinics = [];
    }
  }

  fetchPhysicianClinic(physicianId: any) {
    this.demoService
      .getPhysicianExaminationAddressById(physicianId)
      .subscribe((response) => {
        this.clinics = response.data;
      });
  }

  noWhitespaceValidator(control: FormControl): ValidationErrors | null {
    const value = (control.value || "").trim();
    const isWhitespace = value.length === 0;
    const isValid = value.length > 0 && !isWhitespace;
    return isValid ? null : { whitespace: true, required: true };
  }

  phoneValidator(control: FormControl): ValidationErrors | null {
    const validPhone = /^\d*$/;
    const isValid = validPhone.test(control.value);
    return isValid ? null : { phone: true };
  }

  ssnValidator(control: FormControl): ValidationErrors | null {
    if (!control.value) return null;

    const ssnRegex = /^\d{3}-\d{2}-\d{4}$/; // Validate format: XXX-XX-XXXX
    const isValid = ssnRegex.test(control.value);
    return isValid ? null : { ssn: true };
  }

  openBodyPartsDialog(index: number) {
    const dialogRef = this.dialog.open(BodyPartsComponent, {
      width: "800px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.length > 0) {
        this.injuryDetails.at(index).patchValue({ injuryBodyPart: result });
        this.toastr.success(
          "Injured body part added successfully!",
          "Success",
          {
            timeOut: 2000,
          }
        );
      }
    });
  }

  removeChip(index: number, part: string): void {
    const currentParts = this.injuryDetails
      .at(index)
      .get("injuryBodyPart").value;
    const partIndex = currentParts.indexOf(part);
    if (partIndex >= 0) {
      currentParts.splice(partIndex, 1);
      this.injuryDetails.at(index).patchValue({ injuryBodyPart: currentParts });
    }
  }

  selectedStrikePanelFiles: File[] = [];
  selectedAttorneyDemoFiles: File[] = [];

  onFileSelected(event: any, type: string) {
    const files: FileList = event.target.files;
    for (let i = 0; i < files.length; i++) {
      if (type === "strikePanel") {
        this.selectedStrikePanelFiles.push(files[i]);
        this.form.patchValue({ strikePanel: this.selectedStrikePanelFiles });
      } else if (type === "attorneyDemo") {
        this.selectedAttorneyDemoFiles.push(files[i]);
        this.form.patchValue({ attorneyDemo: this.selectedAttorneyDemoFiles });
      }
    }
  }

  removeFile(index: number, type: string) {
    if (type === "strikePanel") {
      this.selectedStrikePanelFiles.splice(index, 1);
      this.form.patchValue({ strikePanel: this.selectedStrikePanelFiles });
    } else if (type === "attorneyDemo") {
      this.selectedAttorneyDemoFiles.splice(index, 1);
      this.form.patchValue({ attorneyDemo: this.selectedAttorneyDemoFiles });
    }
  }

  fileURL: string = "https://drive.google.com/file/d/";

  viewFile(fileId: string): string {
    return `${this.fileURL}${fileId}/view`;
  }

  buildEncounterData() {
    return {
      reqFirstName: this.form.value.reqFirstName,
      reqMiddleName: this.form.value.reqMiddleName,
      reqLastName: this.form.value.reqLastName,
      reqEmail: this.form.value.reqEmail,
      reqParty: this.form.value.reqParty,
      reqDateRange_start: this.form.value.reqDateRange_start,
      reqDateRange_end: this.form.value.reqDateRange_end,
      physician: this.form.value.physician,
      panelNumber: this.form.value.panelNumber,
      medicalEvaluationType: this.form.value.medicalEvaluationType,
      evaluationType: this.form.value.evaluationType,
      encounterType: this.form.value.encounterType,
      // dateOfAppointment: this.form.value.dateOfAppointment,
      // dateOfAppointmentCall: this.form.value.dateOfAppointmentCall,
      online: this.form.value.online,
      meetingLink: this.form.value.meetingLink,
      examinationAddress: this.form.value.examinationAddress,
      examinationCity: this.form.value.examinationCity,
      examinationState: this.form.value.examinationState,
      examinationZip: this.form.value.examinationZip,
      previousEncounter: this.form.value.previousEncounter,
      applicantName: this.form.value.applicantName,
      applicantMiddleName: this.form.value.applicantMiddleName,
      applicantLastName: this.form.value.applicantLastName,
      dob: this.form.value.dob,
      ssn: this.form.value.ssn,
      gender: this.form.value.gender,
      address: this.form.value.address,
      city: this.form.value.city,
      state: this.form.value.state,
      zip: this.form.value.zip,
      email: this.form.value.email,
      phone: this.form.value.phone,
      altPhone: this.form.value.altPhone,
      jobtitle: this.form.value.jobtitle,
      modeOfTransmission: this.form.value.modeOfTransmission,
      applicantDigitalService: this.form.value.applicantDigitalService,
      injuryDetails: this.form.value.injuryDetails,
      interpreter: this.form.value.interpreter,
      language: this.form.value.language,
      vendor: this.form.value.vendor,
      vendorPhone: this.form.value.vendorPhone,
      unrepresented: this.form.value.unrepresented,
      firm: this.form.value.firm,
      applicantAttorneyname: this.form.value.applicantAttorneyname,
      applicantAttorneyaddress: this.form.value.applicantAttorneyaddress,
      applicantAttorneyCity: this.form.value.applicantAttorneyCity,
      applicantAttorneyState: this.form.value.applicantAttorneyState,
      applicantAttorneyZip: this.form.value.applicantAttorneyZip,
      applicantAttorneyTelePhone: this.form.value.applicantAttorneyTelePhone,
      applicantAttorneyFax: this.form.value.applicantAttorneyFax,
      applicantAttorneyEmail: this.form.value.applicantAttorneyEmail,
      applicantAttorneyAssitantEmail:
        this.form.value.applicantAttorneyAssitantEmail,
      applicantAttorneymodeOfTransmission:
        this.form.value.applicantAttorneymodeOfTransmission,
      applicantAttorneyDigitalService:
        this.form.value.applicantAttorneyDigitalService,

      defenceFirm: this.form.value.defenceFirm,
      defenceName: this.form.value.defenceName,
      defenceAddress: this.form.value.defenceAddress,
      defenceCity: this.form.value.defenceCity,
      defenceState: this.form.value.defenceState,
      defenceZip: this.form.value.defenceZip,
      defencePhone: this.form.value.defencePhone,
      defenceFax: this.form.value.defenceFax,
      defenceEmail: this.form.value.defenceEmail,
      defenceAssistantEmail: this.form.value.defenceAssistantEmail,
      defencemodeOfTransmission: this.form.value.defencemodeOfTransmission,
      defenceDigitalService: this.form.value.defenceDigitalService,
      employer: this.form.value.employer,
      employerPhone: this.form.value.employerPhone,
      employerFax: this.form.value.employerFax,
      employerEmail: this.form.value.employerEmail,
      employerAddress: this.form.value.employerAddress,
      employerCity: this.form.value.employerCity,
      employerState: this.form.value.employerState,
      employerZip: this.form.value.employerZip,
      employermodeOfTransmission: this.form.value.employermodeOfTransmission,
      employerDigitalService: this.form.value.employerDigitalService,

      insuranceCompany: this.form.value.insuranceCompany,
      insuranceCompanyPhone: this.form.value.insuranceCompanyPhone,
      payerId: this.form.value.payerId,
      insurancemodeOfTransmission: this.form.value.insurancemodeOfTransmission,
      billingEmail: this.form.value.billingEmail,
      billingFax: this.form.value.billingFax,
      billingAddress: this.form.value.billingAddress,
      billingReviewPhone: this.form.value.billingReviewPhone,
      billingReviewEmail: this.form.value.billingReviewEmail,
      adjuster: this.form.value.adjuster,
      insuranceAddress: this.form.value.insuranceAddress,
      insuranceCity: this.form.value.insuranceCity,
      insuranceState: this.form.value.insuranceState,
      insuranceZip: this.form.value.insuranceZip,
      insurancePhone: this.form.value.insurancePhone,
      insuranceFax: this.form.value.insuranceFax,
      insuranceEmail: this.form.value.insuranceEmail,
      insuranceAssistant: this.form.value.insuranceAssistant,
      insuranceClaimNo: this.form.value.insuranceClaimNo,
      insuranceDigitalService: this.form.value.insuranceDigitalService,
    };
  }

  onSave() {
    if (this.form.invalid) {
      this.toastr.error(
        "Form is invalid. Please correct the errors and try again."
      );
      return;
    }

    const encounterData = this.buildEncounterData();

    if (
      !encounterData.injuryDetails ||
      encounterData.injuryDetails.length < 1
    ) {
      this.toastr.error("Injury details are required.");
      return;
    }

    if (
      !encounterData.insuranceClaimNo ||
      encounterData.insuranceClaimNo.length < 1
    ) {
      this.toastr.error("Insurance claim number is required.");
      return;
    }

    if (encounterData.encounterType === "PQME") {
      if (
        !this.selectedStrikePanelFiles ||
        this.selectedStrikePanelFiles.length < 1
      ) {
        this.toastr.error("Strike Panel files are required.");
        return;
      }

      if (
        !this.selectedAttorneyDemoFiles ||
        this.selectedAttorneyDemoFiles.length < 1
      ) {
        this.toastr.error("Attorney Demo files are required.");
        return;
      }
    }

    this.isSubmitting = true;

    const encounterObservable = this.demoService.createEncounter(
      encounterData,
      this.selectedStrikePanelFiles,
      this.selectedAttorneyDemoFiles
    );

    encounterObservable.subscribe({
      next: (responseData) => {
        this.isSubmitting = false;
        this.toastr.success(`Data submitted successfully`, "Done!", {
          timeOut: 3000,
          closeButton: true,
        });
      },
      error: (error) => {
        this.isSubmitting = false;
        this.toastr.error(`Failed to submit request`, "Error", {
          timeOut: 3000,
          closeButton: true,
        });
      },
      complete: () => {
        this.isSubmitting = false;
        this.closeDialog();
        this.router.navigate(["/thank_you_for_request_form"]);
      },
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }

  back() {
    this.location.back();
  }

  ngOnDestroy() {
    this.authStatusSub.unsubscribe();
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    this.clinics = [];
    this.physicians = [];
  }
}
