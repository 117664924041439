import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Notification } from "../interfaces/notification.interface";
import { trackById } from "../../../../utils/track-by";
import icSettings from "@iconify/icons-ic/twotone-settings";
import icChevronRight from "@iconify/icons-ic/twotone-chevron-right";
import icNotificationsOff from "@iconify/icons-ic/twotone-notifications-off";
import icClearAll from "@iconify/icons-ic/twotone-clear-all";
import { PopoverRef } from "src/@vex/components/popover/popover-ref";

@Component({
  selector: "vex-toolbar-notifications-dropdown",
  templateUrl: "./toolbar-notifications-dropdown.component.html",
  styleUrls: ["./toolbar-notifications-dropdown.component.scss"],
})
export class ToolbarNotificationsDropdownComponent implements OnInit {
  @Input() notifications: Notification[] = [];
  selectedNotification: Notification | null = null;

  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icClearAll = icClearAll;
  icNotificationsOff = icNotificationsOff;
  trackById = trackById;
  @Output() notificationRead = new EventEmitter<string>();

  onNotificationClick(notificationId: string) {
    this.notificationRead.emit(notificationId);
  }
  constructor(private popoverRef: PopoverRef) {}

  ngOnInit() {
    // Access the data passed to the popover
    this.notifications = this.popoverRef.data.notifications;

    console.log(this.notifications, "toolbarnotificationdropdowncomponent");
  }

  toggleNotification(notification: Notification) {
    this.selectedNotification =
      this.selectedNotification === notification ? null : notification;
  }
}
