<h2 mat-dialog-title>Body Parts</h2>
<mat-dialog-content>
  <!-- <div fxLayout="row" fxLayoutAlign="space-between center" class="search-container">
    <mat-form-field appearance="outline" fxFlex="auto" class="search-field">
      <input matInput [formControl]="searchControl" placeholder="Search body part" />
    </mat-form-field>
    <button mat-button class="primary-button" (click)="addNewBodyPart()">+ Add new</button>
  </div> -->

  <mat-menu #filterMenu="matMenu" class="px-2 py-4">
    <button mat-menu-item [class.selected]="selectedFilter === 'Orthopedics'"
      (click)="applyFilter('Orthopedics')">Orthopedics</button>
    <button mat-menu-item [class.selected]="selectedFilter === 'Anesthesiology'"
      (click)="applyFilter('Anesthesiology')">Anesthesiology</button>
    <button mat-menu-item [class.selected]="selectedFilter === 'Cardiology'"
      (click)="applyFilter('Cardiology')">Cardiology</button>
    <button mat-menu-item [class.selected]="selectedFilter === 'Gastroenterology'"
      (click)="applyFilter('Gastroenterology')">Gastroenterology</button>
    <button mat-menu-item [class.selected]="selectedFilter === 'Obstetrics & Gynecology'"
      (click)="applyFilter('Obstetrics & Gynecology')">Obstetrics & Gynecology</button>
    <button mat-menu-item [class.selected]="selectedFilter === 'Ophthalmology'"
      (click)="applyFilter('Ophthalmology')">Ophthalmology</button>
    <button mat-menu-item [class.selected]="selectedFilter === 'Otolaryngology'"
      (click)="applyFilter('Otolaryngology')">Otolaryngology</button>
  </mat-menu>
  <button mat-button [matMenuTriggerFor]="filterMenu" class="filter-button">
    <mat-icon>filter_list</mat-icon>
    {{ selectedFilter | titlecase }}
    <mat-icon>arrow_drop_down</mat-icon>
  </button>


  <div *ngIf="isLoading" fxLayout="column" fxLayoutAlign="center center"
      class="centered-content">
      <mat-spinner [diameter]="50"></mat-spinner>
  </div>


  <ng-container *ngIf="!isLoading">
    <div class="tab-buttons mt-4">
      <button mat-button (click)="selectTab('Left')" [class.active]="selectedTab === 'Left'">
        <mat-icon *ngIf="selectedTab === 'Left'">check</mat-icon> Left
      </button>
      <button mat-button (click)="selectTab('Right')" [class.active]="selectedTab === 'Right'">
        <mat-icon *ngIf="selectedTab === 'Right'">check</mat-icon> Right
      </button>
      <button mat-button (click)="selectTab('Bilateral')" [class.active]="selectedTab === 'Bilateral'">
        <mat-icon *ngIf="selectedTab === 'Bilateral'">check</mat-icon> Bilateral
      </button>
      <button mat-button (click)="selectTab('Unspecified')" [class.active]="selectedTab === 'Unspecified'">
        <mat-icon *ngIf="selectedTab === 'Unspecified'">check</mat-icon> Unspecified
      </button>
    </div>



    <div *ngIf="filteredParts.length === 0 && !isLoading" fxLayout="column" fxLayoutAlign="center center"
      class="centered-content">
      <img class="h-40 mt-2" src="assets/img/illustrations/idea.svg" alt="No data" />
      <h2 class="headline m-0 text-center">Data not found</h2>

    </div>


    <mat-selection-list *ngIf="filteredParts.length > 0" #bodyPartList class="three-column-list mt-3">
      <div class="column">
        <mat-list-option *ngFor="let part of getPartsForColumn(1)" [value]="part" [selected]="isSelected(part)"
          (click)="toggleSelection(part)">
          {{ part }}
        </mat-list-option>
      </div>
      <div class="column">
        <mat-list-option *ngFor="let part of getPartsForColumn(2)" [value]="part" [selected]="isSelected(part)"
          (click)="toggleSelection(part)">
          {{ part }}
        </mat-list-option>
      </div>
      <div class="column">
        <mat-list-option *ngFor="let part of getPartsForColumn(3)" [value]="part" [selected]="isSelected(part)"
          (click)="toggleSelection(part)">
          {{ part }}
        </mat-list-option>
      </div>
    </mat-selection-list>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-raised-button class="qqmeBGBack" (click)="onCancel()">Cancel</button>
  <button mat-raised-button class="primary-button" (click)="onSave()">Save</button>
</mat-dialog-actions>