import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class CalendarService {
  private apiUrl = `${environment.apiUrl}/admin`; 

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  createEncounter(
    data: any,
    strikePanelFiles: File[],
    attorneyDemoFiles: File[],
  ): Observable<any> {
    const formData = new FormData();

    // formData.append("applicantID", data.applicantID);
    formData.append("physician", data.physician);
    formData.append("panelNumber", data.panelNumber);
    formData.append("medicalEvaluationType", data.medicalEvaluationType);
    formData.append("evaluationType", data.evaluationType);
    formData.append("encounterType", data.encounterType);
    formData.append("dateOfAppointment", data.dateOfAppointment);
    formData.append("dateOfAppointmentCall", data.dateOfAppointmentCall);
    // formData.append("online", data.online);
    // formData.append("meetingLink", data.meetingLink);
    formData.append("examinationAddress", data.examinationAddress);
    formData.append("examinationCity", data.examinationCity);
    formData.append("examinationState", data.examinationState);
    formData.append("examinationZip", data.examinationZip);
    formData.append("previousEncounter", JSON.stringify(data.previousEncounter));
    formData.append("applicantName", data.applicantName);
    formData.append("applicantMiddleName", data.applicantMiddleName);
    formData.append("applicantLastName", data.applicantLastName);
    formData.append("dob", data.dob);
    formData.append("ssn", data.ssn);
    formData.append("gender", data.gender);
    formData.append("address", data.address);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("zip", data.zip);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("altPhone", data.altPhone);
    formData.append("jobtitle", data.jobtitle);
    formData.append("modeOfTransmission", data.modeOfTransmission);
    formData.append("applicantDigitalService", data.applicantDigitalService);
    formData.append("injuryDetails", JSON.stringify(data.injuryDetails));
    formData.append("interpreter", data.interpreter);
    formData.append("language", data.language);
    formData.append("vendor", data.vendor);
    formData.append("vendorPhone", data.vendorPhone);
    formData.append("unrepresented", data.unrepresented);
    formData.append("firm", data.firm);
    formData.append("applicantAttorneyname", data.applicantAttorneyname);
    formData.append("applicantAttorneyaddress", data.applicantAttorneyaddress);
    formData.append("applicantAttorneyCity", data.applicantAttorneyCity);
    formData.append("applicantAttorneyState", data.applicantAttorneyState);
    formData.append("applicantAttorneyZip", data.applicantAttorneyZip);
    formData.append("applicantAttorneyTelePhone", data.applicantAttorneyTelePhone);
    formData.append("applicantAttorneyFax", data.applicantAttorneyFax);
    formData.append("applicantAttorneyEmail", data.applicantAttorneyEmail);
    formData.append("applicantAttorneyAssitantEmail", data.applicantAttorneyAssitantEmail);
    formData.append("applicantAttorneymodeOfTransmission", data.applicantAttorneymodeOfTransmission);
    formData.append(
      "applicantAttorneyDigitalService",
      data.applicantAttorneyDigitalService
    );
    formData.append("defenceFirm", data.defenceFirm);
    formData.append("defenceName", data.defenceName);
    formData.append("defenceAddress", data.defenceAddress);
    formData.append("defenceCity", data.defenceCity);
    formData.append("defenceState", data.defenceState);
    formData.append("defenceZip", data.defenceZip);
    formData.append("defencePhone", data.defencePhone);
    formData.append("defenceFax", data.defenceFax);
    formData.append("defenceEmail", data.defenceEmail);
    formData.append("defenceAssistantEmail", data.defenceAssistantEmail);
    formData.append(
      "defencemodeOfTransmission",
      data.defencemodeOfTransmission
    );
    formData.append(
      "defenceDigitalService",
      data.defenceDigitalService
    );
    formData.append("employer", data.employer);
    formData.append("employerPhone", data.employerPhone);
    formData.append("employerFax", data.employerFax);
    formData.append("employerEmail", data.employerEmail);
    formData.append("employerAddress", data.employerAddress);
    formData.append("employerCity", data.employerCity);
    formData.append("employerState", data.employerState);
    formData.append("employerZip", data.employerZip);
    formData.append(
      "employermodeOfTransmission",
      data.employermodeOfTransmission
    );
    formData.append(
      "employerDigitalService",
      data.employerDigitalService
    );
    formData.append("insuranceCompany", data.insuranceCompany);
    formData.append("insuranceCompanyPhone", data.insuranceCompanyPhone);
    formData.append("payerId", data.payerId);
    // formData.append("companyPayerId", data.companyPayerId);
    formData.append(
      "insurancemodeOfTransmission",
      data.insurancemodeOfTransmission
    );
    formData.append("billingEmail", data.billingEmail);
    formData.append("billingFax", data.billingFax);
    formData.append("billingAddress", data.billingAddress);
    formData.append("billingReviewPhone", data.billingReviewPhone);
    formData.append("billingReviewEmail", data.billingReviewEmail);
    formData.append("adjuster", data.adjuster);
    formData.append("insuranceAddress", data.insuranceAddress);
    formData.append("insuranceCity", data.insuranceCity);
    formData.append("insuranceState", data.insuranceState);
    formData.append("insuranceZip", data.insuranceZip);
    formData.append("insurancePhone", data.insurancePhone);
    formData.append("insuranceFax", data.insuranceFax);
    formData.append("insuranceEmail", data.insuranceEmail);
    formData.append("insuranceAssistant", data.insuranceAssistant);
    formData.append("insuranceClaimNo", data.insuranceClaimNo);
    formData.append(
      "insuranceDigitalService",
      data.insuranceDigitalService
    );
    formData.append("draft", data.isDraft);
    // formData.append("sibtf", data.sibtf);
    // formData.append("subFund", data.subFund);
    // formData.append("subPhone", data.subPhone);
    // formData.append("subFax", data.subFax);
    // formData.append("subAddress", data.subAddress);
    // formData.append("subCity", data.subCity);
    // formData.append("subState", data.subState);
    // formData.append("subZip", data.subZip);
    // formData.append("subEmail", data.subEmail);

    if (strikePanelFiles && strikePanelFiles.length > 0) {
      strikePanelFiles.forEach((file: File) => {
        formData.append("strikePanelFiles", file, file.name);
      });
    }

    if (attorneyDemoFiles && attorneyDemoFiles.length > 0) {
      attorneyDemoFiles.forEach((file: File) => {
        formData.append("attorneyDemoFiles", file, file.name);
      });
    }

    // Post the form data to the server
    return this.http.post<{ message: string; data: any }>(
      `${this.apiUrl}/create_encounter?name=Create Encounter`,
      formData
    );
  }

  updateEncounter(
    data: any,
    strikePanelFiles: File[],
    attorneyDemoFiles: File[],
    id: any
  ): Observable<any> {
    const formData = new FormData();
    // formData.append("applicantID", data.applicantID);
    formData.append("physician", data.physician);
    formData.append("panelNumber", data.panelNumber);
    formData.append("medicalEvaluationType", data.medicalEvaluationType);
    formData.append("evaluationType", data.evaluationType);
    formData.append("encounterType", data.encounterType);
    formData.append("dateOfAppointment", data.dateOfAppointment);
    formData.append("dateOfAppointmentCall", data.dateOfAppointmentCall);
    // formData.append("online", data.online);
    // formData.append("meetingLink", data.meetingLink);
    formData.append("examinationAddress", data.examinationAddress);
    formData.append("examinationCity", data.examinationCity);
    formData.append("examinationState", data.examinationState);
    formData.append("examinationZip", data.examinationZip);
    formData.append("previousEncounter", JSON.stringify(data.previousEncounter));
    formData.append("applicantName", data.applicantName);
    formData.append("applicantMiddleName", data.applicantMiddleName);
    formData.append("applicantLastName", data.applicantLastName);
    formData.append("dob", data.dob);
    formData.append("ssn", data.ssn);
    formData.append("gender", data.gender);
    formData.append("address", data.address);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("zip", data.zip);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("altPhone", data.altPhone);
    formData.append("jobtitle", data.jobtitle);
    formData.append("modeOfTransmission", data.modeOfTransmission);
    formData.append("applicantDigitalService", data.applicantDigitalService);
    formData.append("injuryDetails", JSON.stringify(data.injuryDetails));
    formData.append("interpreter", data.interpreter);
    formData.append("language", data.language);
    formData.append("vendor", data.vendor);
    formData.append("vendorPhone", data.vendorPhone);
    formData.append("unrepresented", data.unrepresented);
    formData.append("firm", data.firm);
    formData.append("applicantAttorneyname", data.applicantAttorneyname);
    formData.append("applicantAttorneyaddress", data.applicantAttorneyaddress);
    formData.append("applicantAttorneyCity", data.applicantAttorneyCity);
    formData.append("applicantAttorneyState", data.applicantAttorneyState);
    formData.append("applicantAttorneyZip", data.applicantAttorneyZip);
    formData.append("applicantAttorneyTelePhone", data.applicantAttorneyTelePhone);
    formData.append("applicantAttorneyFax", data.applicantAttorneyFax);
    formData.append("applicantAttorneyEmail", data.applicantAttorneyEmail);
    formData.append("applicantAttorneyAssitantEmail", data.applicantAttorneyAssitantEmail);
    formData.append("applicantAttorneymodeOfTransmission", data.applicantAttorneymodeOfTransmission);
    formData.append(
      "applicantAttorneyDigitalService",
      data.applicantAttorneyDigitalService
    );
    formData.append("defenceFirm", data.defenceFirm);
    formData.append("defenceName", data.defenceName);
    formData.append("defenceAddress", data.defenceAddress);
    formData.append("defenceCity", data.defenceCity);
    formData.append("defenceState", data.defenceState);
    formData.append("defenceZip", data.defenceZip);
    formData.append("defencePhone", data.defencePhone);
    formData.append("defenceFax", data.defenceFax);
    formData.append("defenceEmail", data.defenceEmail);
    formData.append("defenceAssistantEmail", data.defenceAssistantEmail);
    formData.append(
      "defencemodeOfTransmission",
      data.defencemodeOfTransmission
    );
    formData.append(
      "defenceDigitalService",
      data.defenceDigitalService
    );
    formData.append("employer", data.employer);
    formData.append("employerPhone", data.employerPhone);
    formData.append("employerFax", data.employerFax);
    formData.append("employerEmail", data.employerEmail);
    formData.append("employerAddress", data.employerAddress);
    formData.append("employerCity", data.employerCity);
    formData.append("employerState", data.employerState);
    formData.append("employerZip", data.employerZip);
    formData.append(
      "employermodeOfTransmission",
      data.employermodeOfTransmission
    );
    formData.append(
      "employerDigitalService",
      data.employerDigitalService
    );
    formData.append("insuranceCompany", data.insuranceCompany);
    formData.append("insuranceCompanyPhone", data.insuranceCompanyPhone);
    formData.append("payerId", data.payerId);
    // formData.append("companyPayerId", data.companyPayerId);
    formData.append(
      "insurancemodeOfTransmission",
      data.insurancemodeOfTransmission
    );
    formData.append("billingEmail", data.billingEmail);
    formData.append("billingFax", data.billingFax);
    formData.append("billingAddress", data.billingAddress);
    formData.append("billingReviewPhone", data.billingReviewPhone);
    formData.append("billingReviewEmail", data.billingReviewEmail);
    formData.append("adjuster", data.adjuster);
    formData.append("insuranceAddress", data.insuranceAddress);
    formData.append("insuranceCity", data.insuranceCity);
    formData.append("insuranceState", data.insuranceState);
    formData.append("insuranceZip", data.insuranceZip);
    formData.append("insurancePhone", data.insurancePhone);
    formData.append("insuranceFax", data.insuranceFax);
    formData.append("insuranceEmail", data.insuranceEmail);
    formData.append("insuranceAssistant", data.insuranceAssistant);
    formData.append("insuranceClaimNo", data.insuranceClaimNo);
    formData.append(
      "insuranceDigitalService",
      data.insuranceDigitalService
    );
    formData.append("draft", data.isDraft);
    // formData.append("sibtf", data.sibtf);
    // formData.append("subFund", data.subFund);
    // formData.append("subPhone", data.subPhone);
    // formData.append("subFax", data.subFax);
    // formData.append("subAddress", data.subAddress);
    // formData.append("subCity", data.subCity);
    // formData.append("subState", data.subState);
    // formData.append("subZip", data.subZip);
    // formData.append("subEmail", data.subEmail);

    strikePanelFiles.forEach((file: File, index: number) => {
      formData.append(`strikePanelFiles`, file, file.name);
    });

    attorneyDemoFiles.forEach((file: File, index: number) => {
      formData.append(`attorneyDemoFiles`, file, file.name);
    });

    return this.http.post<{ message: string; data: any }>(
      `${this.apiUrl}/update_encounter/${id}?name=Update Encounter`,
      formData
    );
  }


  // SIBTF

  createSIBTFEncounter(
    data: any,
    attorneyDemoFiles: File[],
  ): Observable<any> {
    const formData = new FormData();

    // formData.append("applicantID", data.applicantID);
    formData.append("physician", data.physician);
    formData.append("medicalEvaluationType", data.medicalEvaluationType);
    formData.append("evaluationType", data.evaluationType);
    formData.append("encounterType", data.encounterType);
    formData.append("dateOfAppointment", data.dateOfAppointment);
    formData.append("dateOfAppointmentCall", data.dateOfAppointmentCall);
    // formData.append("online", data.online);
    // formData.append("meetingLink", data.meetingLink);
    formData.append("examinationAddress", data.examinationAddress);
    formData.append("examinationCity", data.examinationCity);
    formData.append("examinationState", data.examinationState);
    formData.append("examinationZip", data.examinationZip);
    formData.append("previousEncounter", JSON.stringify(data.previousEncounter));
    formData.append("applicantName", data.applicantName);
    formData.append("applicantMiddleName", data.applicantMiddleName);
    formData.append("applicantLastName", data.applicantLastName);
    formData.append("dob", data.dob);
    formData.append("ssn", data.ssn);
    formData.append("gender", data.gender);
    formData.append("address", data.address);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("zip", data.zip);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("altPhone", data.altPhone);
    formData.append("jobtitle", data.jobtitle);
    formData.append("modeOfTransmission", data.modeOfTransmission);
    formData.append("applicantDigitalService", data.applicantDigitalService);
    formData.append("interpreter", data.interpreter);
    formData.append("language", data.language);
    formData.append("vendor", data.vendor);
    formData.append("vendorPhone", data.vendorPhone);
    formData.append("unrepresented", data.unrepresented);
    formData.append("firm", data.firm);
    formData.append("applicantAttorneyname", data.applicantAttorneyname);
    formData.append("applicantAttorneyaddress", data.applicantAttorneyaddress);
    formData.append("applicantAttorneyCity", data.applicantAttorneyCity);
    formData.append("applicantAttorneyState", data.applicantAttorneyState);
    formData.append("applicantAttorneyZip", data.applicantAttorneyZip);
    formData.append("applicantAttorneyTelePhone", data.applicantAttorneyTelePhone);
    formData.append("applicantAttorneyFax", data.applicantAttorneyFax);
    formData.append("applicantAttorneyEmail", data.applicantAttorneyEmail);
    formData.append("applicantAttorneyAssitantEmail", data.applicantAttorneyAssitantEmail);
    formData.append("applicantAttorneymodeOfTransmission", data.applicantAttorneymodeOfTransmission);
    formData.append(
      "applicantAttorneyDigitalService",
      data.applicantAttorneyDigitalService
    );

    formData.append("employer", data.employer);
    formData.append("employerPhone", data.employerPhone);
    formData.append("employerFax", data.employerFax);
    formData.append("employerEmail", data.employerEmail);
    formData.append("employerAddress", data.employerAddress);
    formData.append("employerCity", data.employerCity);
    formData.append("employerState", data.employerState);
    formData.append("employerZip", data.employerZip);
    formData.append(
      "employermodeOfTransmission",
      data.employermodeOfTransmission
    );
    formData.append(
      "employerDigitalService",
      data.employerDigitalService
    );

    formData.append("draft", data.isDraft);
    formData.append("sibtf", data.sibtf);
    formData.append("subFund", data.subFund);
    formData.append("subPhone", data.subPhone);
    formData.append("subFax", data.subFax);
    formData.append("subAddress", data.subAddress);
    formData.append("subCity", data.subCity);
    formData.append("subState", data.subState);
    formData.append("subZip", data.subZip);
    formData.append("subEmail", data.subEmail);

    if (attorneyDemoFiles && attorneyDemoFiles.length > 0) {
      attorneyDemoFiles.forEach((file: File) => {
        formData.append("attorneyDemoFiles", file, file.name);
      });
    }

    // Post the form data to the server
    return this.http.post<{ message: string; data: any }>(
      `${this.apiUrl}/create_encounter?name=Create Encounter`,
      formData
    );
  }

  updateSIBTFEncounter(
    data: any,
    attorneyDemoFiles: File[],
    id: any
  ): Observable<any> {
    const formData = new FormData();
    formData.append("physician", data.physician);
    formData.append("medicalEvaluationType", data.medicalEvaluationType);
    formData.append("evaluationType", data.evaluationType);
    formData.append("encounterType", data.encounterType);
    formData.append("dateOfAppointment", data.dateOfAppointment);
    formData.append("dateOfAppointmentCall", data.dateOfAppointmentCall);
    formData.append("examinationAddress", data.examinationAddress);
    formData.append("examinationCity", data.examinationCity);
    formData.append("examinationState", data.examinationState);
    formData.append("examinationZip", data.examinationZip);
    formData.append("previousEncounter", JSON.stringify(data.previousEncounter));
    formData.append("applicantName", data.applicantName);
    formData.append("applicantMiddleName", data.applicantMiddleName);
    formData.append("applicantLastName", data.applicantLastName);
    formData.append("dob", data.dob);
    formData.append("ssn", data.ssn);
    formData.append("gender", data.gender);
    formData.append("address", data.address);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("zip", data.zip);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("altPhone", data.altPhone);
    formData.append("jobtitle", data.jobtitle);
    formData.append("modeOfTransmission", data.modeOfTransmission);
    formData.append("applicantDigitalService", data.applicantDigitalService);
    formData.append("interpreter", data.interpreter);
    formData.append("language", data.language);
    formData.append("vendor", data.vendor);
    formData.append("vendorPhone", data.vendorPhone);
    formData.append("unrepresented", data.unrepresented);
    formData.append("firm", data.firm);
    formData.append("applicantAttorneyname", data.applicantAttorneyname);
    formData.append("applicantAttorneyaddress", data.applicantAttorneyaddress);
    formData.append("applicantAttorneyCity", data.applicantAttorneyCity);
    formData.append("applicantAttorneyState", data.applicantAttorneyState);
    formData.append("applicantAttorneyZip", data.applicantAttorneyZip);
    formData.append("applicantAttorneyTelePhone", data.applicantAttorneyTelePhone);
    formData.append("applicantAttorneyFax", data.applicantAttorneyFax);
    formData.append("applicantAttorneyEmail", data.applicantAttorneyEmail);
    formData.append("applicantAttorneyAssitantEmail", data.applicantAttorneyAssitantEmail);
    formData.append("applicantAttorneymodeOfTransmission", data.applicantAttorneymodeOfTransmission);
    formData.append(
      "applicantAttorneyDigitalService",
      data.applicantAttorneyDigitalService
    );
 
    formData.append("employer", data.employer);
    formData.append("employerPhone", data.employerPhone);
    formData.append("employerFax", data.employerFax);
    formData.append("employerEmail", data.employerEmail);
    formData.append("employerAddress", data.employerAddress);
    formData.append("employerCity", data.employerCity);
    formData.append("employerState", data.employerState);
    formData.append("employerZip", data.employerZip);
    formData.append(
      "employermodeOfTransmission",
      data.employermodeOfTransmission
    );
    formData.append(
      "employerDigitalService",
      data.employerDigitalService
    );

    formData.append("draft", data.isDraft);
    formData.append("sibtf", data.sibtf);
    formData.append("subFund", data.subFund);
    formData.append("subPhone", data.subPhone);
    formData.append("subFax", data.subFax);
    formData.append("subAddress", data.subAddress);
    formData.append("subCity", data.subCity);
    formData.append("subState", data.subState);
    formData.append("subZip", data.subZip);
    formData.append("subEmail", data.subEmail);


    attorneyDemoFiles.forEach((file: File, index: number) => {
      formData.append(`attorneyDemoFiles`, file, file.name);
    });

    return this.http.post<{ message: string; data: any }>(
      `${this.apiUrl}/update_encounter/${id}?name=Update Encounter`,
      formData
    );
  }

  getEncounterById(id: string) {
    return this.http.get<{
      data: any;
    }>(`${this.apiUrl}/get_encounter/${id}`);
  }

  getPhysicianExaminationAddressById(physicianId: string) {
    return this.http.get<{
      data: any;
    }>(`${this.apiUrl}/clinic_locations/physician/${physicianId}`);
  }

  getInsuranceComp() {
    return this.http.get<{
      data: any;
    }>(`${this.apiUrl}/getAll_insuranceCompany_details`);
  }

  getPhysicians(): Observable<any> {
    return this.http.get<{ status: string; results: number; data: any }>(
      `${this.apiUrl}/getPhysician`
    );
  }

  getAllApplicants(): Observable<any> {
    return this.http.get<{ status: string; data: any }>(
      `${this.apiUrl}/get_applicant`
    );
  }

  getAllEmployers(): Observable<any> {
    return this.http.get<{ status: string; data: any }>(
      `${this.apiUrl}/getAll_employers_data`
    );
  }

  
  getAllAttorney(): Observable<any> {
    return this.http.get<{ status: string; data: any }>(
      `${this.apiUrl}/getAll_Attorney/details`
    );
  }

  delete(id: string): Observable<any> {
    return this.http.post<any>(
      `${this.apiUrl}/delete_encounter/${id}?name=Delete Encounter`,
      {}
    );
  }

  getEncounters(): Observable<any> {
    return this.http.get<{ status: string; results: number; data: any }>(
      `${this.apiUrl}/get_inprogress_encounters?name=View All Calendar Events`
    );
  }

  getAllEncounters(): Observable<any> {
    return this.http.get<{ status: string; results: number; data: any }>(
      `${this.apiUrl}/get_all_encounters?name=View Encounters`
    );
  }

  getAllBodyParts(category:any): Observable<any> {
    return this.http.get<{ status: string; data: any }>(
      `${this.apiUrl}/getAll_bodyparts?category=${category}`
    );
  }


  getPDF(encounterData: any, type: any) {
    return this.http.post(`${this.apiUrl}/generate-pdf?recipientType=${type}`, {encounterData:encounterData}, {
      responseType: 'blob'  
    });
  }

  getPDFEmail(encounterData: any) {
    return this.http.post(`${this.apiUrl}/generate_pdf_email`, {encounterData:encounterData}, {
      responseType: 'blob'  
    });
  }

  checkApplicantEncounter(applicantName: any,applicantLastName: any,dob: any, physician:any):Observable<any> {
    return this.http.post(`${this.apiUrl}/checkApplicantEncounter`, {applicantName,applicantLastName,dob,physician});
  }


}
