<div class="header">
    <h2 mat-dialog-title>{{data?.title}}</h2>
    <button mat-icon-button [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <p>{{data?.message}}</p>
</div>

<div mat-dialog-actions [align]="'end'">
    <button mat-raised-button [mat-dialog-close]="false">
        {{data?.cancelCaption}}
    </button>
    <button mat-raised-button class="primary-button" [mat-dialog-close]="true">
        {{data?.confirmCaption}}
    </button>
</div>