<div
  (mouseenter)="onMouseEnter()"
  (mouseleave)="onMouseLeave()"
  [class.collapsed]="collapsed"
  [class.open]="collapsed && collapsedOpen$ | async"
  class="sidenav flex flex-col "
>
  <div class="sidenav-toolbar sidenav-items flex-none flex items-center ">
<div (click)="navigateToDashboard()" style="cursor: pointer;">
  <img src="/assets/Ortholegle Logo.png" class="w-8 select-none flex-none" />
</div>
    <h2 class="title_22 ltr:pl-4 rtl:pr-4 select-none flex-auto">ORTHOLEGAL</h2>
  
    <!-- <button
      (click)="toggleCollapse()"
      *ngIf="showCollapsePin$ | async"
      class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
      mat-icon-button
      type="button"
    >
      <mat-icon
        *ngIf="!collapsed"
        [icIcon]="icRadioButtonChecked"
        size="14px"
      ></mat-icon>
      <mat-icon
        *ngIf="collapsed"
        [icIcon]="icRadioButtonUnchecked"
        size="14px"
      ></mat-icon>
    </button> -->

    <button
    (click)="toggleCollapse()"
    *ngIf="showCollapsePin$ | async"
    class="w-8 h-8 -mr-2 leading-none flex-none hidden lg:block"
    mat-icon-button
    type="button"
    [matTooltip]="collapsed ? 'Expand' : 'Collapse'"
  >
    <mat-icon 
      aria-hidden="true"
      [ngClass]="{ 'icon-collapsed': collapsed, 'icon-expanded': !collapsed }"
    >
      menu
    </mat-icon>
  </button>
  
  </div>

  <vex-scrollbar class="flex-auto">
    <div class="">
      <vex-sidenav-item
        *ngFor="let item of items; trackBy: trackByRoute"
        [item]="item"
        [level]="0"
      ></vex-sidenav-item>
    </div>
  </vex-scrollbar>
</div>
