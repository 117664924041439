import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AuthInterceptor } from "./pages/auth/auth-interceptor";
import { VexModule } from "../@vex/vex.module";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { CustomLayoutModule } from "./custom-layout/custom-layout.module";
import { MaterialModule } from "../material.module";
import { ErrorInterceptor } from "./error-interceptor";
import { ErrorComponent } from "./pages/error/error.component";
import { ToastrModule } from "ngx-toastr";
import { NgxSpinnerModule } from "ngx-spinner";
import { LocationStrategy, HashLocationStrategy } from "@angular/common";
import { ConfirmDialogBoxComponent } from "./pages/confirm-dialog-box/confirm-dialog-box.component";
import { OwlDateTimeModule, OwlNativeDateTimeModule } from "ng-pick-datetime";
import { AngularFireModule } from "@angular/fire";
import { AngularFireMessagingModule } from "@angular/fire/messaging";

import { environment } from "src/environments/environment";
import { DigitalServiceFormComponent } from "./pages/auth/digital-service-form/digital-service-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";
import { SignaturePadModule } from "angular2-signaturepad";
import { ThankyouComponent } from "./pages/auth/thankyou/thankyou.component";
import { DemoComponent } from "./demo/demo.component";
import { RecaptchaModule } from "ng-recaptcha";
import { BodyPartsComponent } from "./demo/body-parts/body-parts.component";
@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    ConfirmDialogBoxComponent,
    DigitalServiceFormComponent,
    ThankyouComponent,
    DemoComponent,
    BodyPartsComponent,
    ThankyouComponent,
  ],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFireMessagingModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    RecaptchaModule,
    HttpClientModule,
    VexModule,
    CustomLayoutModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      positionClass: "toast-bottom-left",
      preventDuplicates: true,
    }),
    NgxSpinnerModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    MaterialModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
  entryComponents: [ErrorComponent],
})
export class AppModule {}
