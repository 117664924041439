import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GoogleAuthService {
    private CLIENT_ID = "1083964933854-27euk886jmsm6tiblt71ha54ka32gj9r.apps.googleusercontent.com";
    private REDIRECT_URI = "http://localhost:8080/oauth2callback"; 
    private SCOPES = "https://www.googleapis.com/auth/drive.file";

    public getAuthorizationUrl(): string {
      const authUrl = new URL('https://accounts.google.com/o/oauth2/v2/auth');
      authUrl.searchParams.append('response_type', 'code');
      authUrl.searchParams.append('client_id', this.CLIENT_ID);
      authUrl.searchParams.append('redirect_uri', this.REDIRECT_URI);
      authUrl.searchParams.append('scope', this.SCOPES);
      authUrl.searchParams.append('access_type', 'offline'); 
      authUrl.searchParams.append('include_granted_scopes', 'true');
      authUrl.searchParams.append('state', 'state_parameter_passthrough_value');
      return authUrl.toString();
    }
}
