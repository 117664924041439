<div class="w-full h-full bg-pattern" fxLayout="row">
    <div class="right">
        <div class="text-center mt-4">
            <h2 class="form-title">Digital Acceptance Form!</h2>
            <h4 class="form-subtitle">Please fill in all details.</h4>
        </div>

        <div @fadeInUp class=" overflow-auto w-1/2 h-[100vh]">
            <div class="p-4" fxLayout="column" fxLayoutGap="10px">
                <form (submit)="onSave(form)" #form="ngForm" class="mb-2">
                    <h4>Please Add your signature here</h4>

                    <canvas #canvas (touchstart)="startDrawing($event)" (touchmove)="moved($event)" width="680"
                        height="100" style="border: 1px solid #ccc; border-radius: 4px; background-color: white;">
                    </canvas>

                    <p class="error-message text-red-500 mt-2" *ngIf="signatureNeeded">Signature is required</p>

                    <div class="buttons flex justify-between">
                        <button mat-button (click)="clearPad()" type="button">
                            <mat-icon>clear</mat-icon> Clear
                        </button>
                    </div>

                    <button mat-raised-button matTooltip="Submit" class="primary-button" type="submit"
                        [class.spinner]="isLoading" [disabled]="isLoading">
                        Submit
                    </button>
                </form>
            </div>
        </div>
    </div>
</div>
