import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { PopoverService } from '../../../components/popover/popover.service';
import { ToolbarUserDropdownComponent } from './toolbar-user-dropdown/toolbar-user-dropdown.component';
import icPerson from '@iconify/icons-ic/twotone-person';
import { AuthService } from 'src/app/pages/auth/authServices';
import { Subscription } from 'rxjs';
import { AdminProfileService } from 'src/app/pages/auth/profile-tab/api.service';
import { Admins } from 'src/app/pages/auth/profile-tab/model';

@Component({
  selector: 'vex-toolbar-user',
  templateUrl: './toolbar-user.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarUserComponent implements OnInit {
  userIsAuthenticated = false;
  userName: string;
  userId: string;
  private authStatusSub: Subscription;
  dropdownOpen: boolean;
  icPerson = icPerson;
  data: Admins;
  roleName: string;
  constructor(private popover: PopoverService,
              private cd: ChangeDetectorRef,  private authService: AuthService, public service: AdminProfileService,) { }

  ngOnInit() {

    this.userName = this.authService.getuserName();
     this.roleName =  this.authService.getRoleName();
  }

  showPopover(originRef: HTMLElement) {
    this.dropdownOpen = true;
    this.cd.markForCheck();

    const popoverRef = this.popover.open({
      content: ToolbarUserDropdownComponent,
      origin: originRef,
      offsetY: 12,
      position: [
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom'
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top',
        },
      ]
    });

    popoverRef.afterClosed$.subscribe(() => {
      this.dropdownOpen = false;
      this.cd.markForCheck();
    });
  }


}
