import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { MenuItem } from "../interfaces/menu-item.interface";
import { trackById } from "../../../../utils/track-by";
import icPerson from "@iconify/icons-ic/twotone-person";
import icSettings from "@iconify/icons-ic/twotone-settings";
import icAccountCircle from "@iconify/icons-ic/twotone-account-circle";
import icCheckCircle from "@iconify/icons-ic/twotone-check-circle";
import icAccessTime from "@iconify/icons-ic/twotone-access-time";
import icDoNotDisturb from "@iconify/icons-ic/twotone-do-not-disturb";
import icOfflineBolt from "@iconify/icons-ic/twotone-offline-bolt";
import icChevronRight from "@iconify/icons-ic/twotone-chevron-right";
import icArrowDropDown from "@iconify/icons-ic/twotone-arrow-drop-down";
import icBusiness from "@iconify/icons-ic/twotone-business";
import icVerifiedUser from "@iconify/icons-ic/twotone-verified-user";
import icLock from "@iconify/icons-ic/twotone-lock";
import icNotificationsOff from "@iconify/icons-ic/twotone-notifications-off";
import { Icon } from "@visurel/iconify-angular";
import { PopoverRef } from "../../../../components/popover/popover-ref";
import { AuthService } from "../../../../../app/pages/auth/authServices";
import { Subscription } from "rxjs";
import { AdminProfileService } from "src/app/pages/auth/profile-tab/api.service";
import icPhone from '@iconify/icons-ic/twotone-phone';
import icMail from '@iconify/icons-ic/twotone-mail';

export interface OnlineStatus {
  id: "online" | "away" | "dnd" | "offline";
  label: string;
  icon: Icon;
  colorClass: string;
}

@Component({
  selector: "vex-toolbar-user-dropdown",
  templateUrl: "./toolbar-user-dropdown.component.html",
  styleUrls: ["./toolbar-user-dropdown.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarUserDropdownComponent implements OnInit, OnDestroy {
  userIsAuthenticated = false;
  private authListenerSubs: Subscription;
  userName: string;
  userId: string;
  isLoading: boolean = false;
  data: any;

  // Menu Items
  items: MenuItem[] = [
    {
      id: "1",
      icon: icAccountCircle,
      label: "My Profile",
      description: "Personal Information",
      colorClass: "text-teal",
      route: "/admin",
    },
  ];

  // Statuses
  statuses: OnlineStatus[] = [
    { id: "online", label: "Online", icon: icCheckCircle, colorClass: "text-green" },
    { id: "away", label: "Away", icon: icAccessTime, colorClass: "text-orange" },
    { id: "dnd", label: "Do not disturb", icon: icDoNotDisturb, colorClass: "text-red" },
    { id: "offline", label: "Offline", icon: icOfflineBolt, colorClass: "text-gray" },
  ];

  activeStatus: OnlineStatus = this.statuses[0];

  // Icons
  trackById = trackById;
  icPerson = icPerson;
  icSettings = icSettings;
  icChevronRight = icChevronRight;
  icArrowDropDown = icArrowDropDown;
  icBusiness = icBusiness;
  icVerifiedUser = icVerifiedUser;
  icPhone = icPhone;
  icMail = icMail;
  icLock = icLock;
  icNotificationsOff = icNotificationsOff;

  constructor(
    private cd: ChangeDetectorRef,
    private popoverRef: PopoverRef<ToolbarUserDropdownComponent>,
    private authService: AuthService,
    public service: AdminProfileService
  ) {}

  ngOnInit(): void {
    this.initializeUserData();
  }

  // Initialize User Data and Loading State
  initializeUserData() {
    this.isLoading = true;  // Start loading state

    // Check authentication status
    this.userIsAuthenticated = this.authService.getIsAuth();
    this.userName = this.authService.getuserName();
    this.userId = this.authService.getUserId();

    this.authListenerSubs = this.authService.getAuthStatusListener().subscribe((isAuthenticated) => {
      this.userIsAuthenticated = isAuthenticated;
    });

    this.getAdminData();
  }

  // Fetch admin data from API
  getAdminData() {
    this.service.getByID(this.userId).subscribe({
      next: (response) => {
        this.data = response.data.data;
        this.isLoading = false; // Stop loading state on success
        this.cd.markForCheck(); // Trigger change detection for data update
      },
      error: () => {
        this.isLoading = false; // Stop loading state on error
        this.data = null; // Clear data in case of error
        this.cd.markForCheck();
      }
    });
  }

  // Handle user logout
  onLogout(): void {
    this.popoverRef.close();
    this.authService.logout();
  }

  // Set online status
  setStatus(status: OnlineStatus): void {
    this.activeStatus = status;
    this.cd.markForCheck();
  }

  // Close the popover
  close(): void {
    this.popoverRef.close();
  }

  ngOnDestroy(): void {
    this.authListenerSubs.unsubscribe(); // Unsubscribe to prevent memory leaks
  }
}
