import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ToastrService } from "ngx-toastr";
import { Router } from "@angular/router";
@Injectable({
  providedIn: "root",
})
export class DemoService {
  private apiUrl = `${environment.apiUrl}/admin`;

  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService
  ) {}

  createEncounter(
    data: any,
    strikePanelFiles: File[],
    attorneyDemoFiles: File[]
  ): Observable<any> {
    const formData = new FormData();

    formData.append("reqFirstName", data.reqFirstName);
    formData.append("reqMiddleName", data.reqMiddleName);
    formData.append("reqLastName", data.reqLastName);
    formData.append("reqEmail", data.reqEmail);
    formData.append("reqParty", data.reqParty);
    formData.append("reqDateRange_start", data.reqDateRange_start);
    formData.append("reqDateRange_end", data.reqDateRange_end);
    formData.append("physician", data.physician);
    formData.append("panelNumber", data.panelNumber);
    formData.append("medicalEvaluationType", data.medicalEvaluationType);
    formData.append("evaluationType", data.evaluationType);
    formData.append("encounterType", data.encounterType);
    // formData.append("dateOfAppointment", data.dateOfAppointment);
    // formData.append("dateOfAppointmentCall", data.dateOfAppointmentCall);
    formData.append("examinationAddress", data.examinationAddress);
    formData.append("examinationCity", data.examinationCity);
    formData.append("examinationState", data.examinationState);
    formData.append("examinationZip", data.examinationZip);
    formData.append(
      "previousEncounter",
      JSON.stringify(data.previousEncounter)
    );
    formData.append("applicantName", data.applicantName);
    formData.append("applicantMiddleName", data.applicantMiddleName);
    formData.append("applicantLastName", data.applicantLastName);
    formData.append("dob", data.dob);
    formData.append("ssn", data.ssn);
    formData.append("gender", data.gender);
    formData.append("address", data.address);
    formData.append("city", data.city);
    formData.append("state", data.state);
    formData.append("zip", data.zip);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("altPhone", data.altPhone);
    formData.append("jobtitle", data.jobtitle);
    formData.append("modeOfTransmission", data.modeOfTransmission);
    formData.append("applicantDigitalService", data.applicantDigitalService);
    formData.append("injuryDetails", JSON.stringify(data.injuryDetails));
    formData.append("interpreter", data.interpreter);
    formData.append("language", data.language);
    formData.append("vendor", data.vendor);
    formData.append("vendorPhone", data.vendorPhone);
    formData.append("unrepresented", data.unrepresented);
    formData.append("firm", data.firm);
    formData.append("applicantAttorneyname", data.applicantAttorneyname);
    formData.append("applicantAttorneyaddress", data.applicantAttorneyaddress);
    formData.append("applicantAttorneyCity", data.applicantAttorneyCity);
    formData.append("applicantAttorneyState", data.applicantAttorneyState);
    formData.append("applicantAttorneyZip", data.applicantAttorneyZip);
    formData.append(
      "applicantAttorneyTelePhone",
      data.applicantAttorneyTelePhone
    );
    formData.append("applicantAttorneyFax", data.applicantAttorneyFax);
    formData.append("applicantAttorneyEmail", data.applicantAttorneyEmail);
    formData.append(
      "applicantAttorneyAssitantEmail",
      data.applicantAttorneyAssitantEmail
    );
    formData.append(
      "applicantAttorneymodeOfTransmission",
      data.applicantAttorneymodeOfTransmission
    );
    formData.append(
      "applicantAttorneyDigitalService",
      data.applicantAttorneyDigitalService
    );
    formData.append("defenceFirm", data.defenceFirm);
    formData.append("defenceName", data.defenceName);
    formData.append("defenceAddress", data.defenceAddress);
    formData.append("defenceCity", data.defenceCity);
    formData.append("defenceState", data.defenceState);
    formData.append("defenceZip", data.defenceZip);
    formData.append("defencePhone", data.defencePhone);
    formData.append("defenceFax", data.defenceFax);
    formData.append("defenceEmail", data.defenceEmail);
    formData.append("defenceAssistantEmail", data.defenceAssistantEmail);
    formData.append(
      "defencemodeOfTransmission",
      data.defencemodeOfTransmission
    );
    formData.append("defenceDigitalService", data.defenceDigitalService);
    formData.append("employer", data.employer);
    formData.append("employerPhone", data.employerPhone);
    formData.append("employerFax", data.employerFax);
    formData.append("employerEmail", data.employerEmail);
    formData.append("employerAddress", data.employerAddress);
    formData.append("employerCity", data.employerCity);
    formData.append("employerState", data.employerState);
    formData.append("employerZip", data.employerZip);
    formData.append(
      "employermodeOfTransmission",
      data.employermodeOfTransmission
    );
    formData.append("employerDigitalService", data.employerDigitalService);
    formData.append("insuranceCompany", data.insuranceCompany);
    formData.append("insuranceCompanyPhone", data.insuranceCompanyPhone);
    formData.append("payerId", data.payerId);
    formData.append(
      "insurancemodeOfTransmission",
      data.insurancemodeOfTransmission
    );
    formData.append("billingEmail", data.billingEmail);
    formData.append("billingFax", data.billingFax);
    formData.append("billingAddress", data.billingAddress);
    formData.append("billingReviewPhone", data.billingReviewPhone);
    formData.append("billingReviewEmail", data.billingReviewEmail);
    formData.append("adjuster", data.adjuster);
    formData.append("insuranceAddress", data.insuranceAddress);
    formData.append("insuranceCity", data.insuranceCity);
    formData.append("insuranceState", data.insuranceState);
    formData.append("insuranceZip", data.insuranceZip);
    formData.append("insurancePhone", data.insurancePhone);
    formData.append("insuranceFax", data.insuranceFax);
    formData.append("insuranceEmail", data.insuranceEmail);
    formData.append("insuranceAssistant", data.insuranceAssistant);
    formData.append("insuranceClaimNo", data.insuranceClaimNo);
    formData.append("insuranceDigitalService", data.insuranceDigitalService);

    if (strikePanelFiles && strikePanelFiles.length > 0) {
      strikePanelFiles.forEach((file: File) => {
        formData.append("strikePanelFiles", file, file.name);
      });
    }

    if (attorneyDemoFiles && attorneyDemoFiles.length > 0) {
      attorneyDemoFiles.forEach((file: File) => {
        formData.append("attorneyDemoFiles", file, file.name);
      });
    }

    // Post the form data to the server
    return this.http.post<{ message: string; data: any }>(
      `${this.apiUrl}/create_Req_encounter`,
      formData
    );
  }

  getPhysicianExaminationAddressById(physicianId: string) {
    return this.http.get<{
      data: any;
    }>(`${this.apiUrl}/clinic_locations_2/physician/${physicianId}`);
  }

  getPhysicians(): Observable<any> {
    return this.http.get<{ status: string; results: number; data: any }>(
      `${this.apiUrl}/getPhysician_2`
    );
  }

  getAllBodyParts(category: any): Observable<any> {
    return this.http.get<{ status: string; data: any }>(
      `${this.apiUrl}/getAll_bodyparts_2?category=${category}`
    );
  }
}
