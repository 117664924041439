import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  ViewChild,
} from "@angular/core";
import { MatMenuTrigger } from "@angular/material/menu";
import { FcmService } from "src/app/fcm.service";
import { AuthService } from "src/app/pages/auth/authServices";
import * as moment from "moment";

@Component({
  selector: "vex-toolbar-notifications",
  templateUrl: "./toolbar-notifications.component.html",
  styleUrls: ["./toolbar-notifications.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ToolbarNotificationsComponent implements OnInit {
  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  dropdownOpen: boolean;
  notifications: any[] = [];
  unreadCount: number = 0;
  filterType = "all"; // Default filter type is 'all'
  filteredNotifications: any[] = [];
  isLoading: boolean = true; // Track loading state
  private notificationBuffer: any[] = [];
  private notificationTimeout: any;
  
  constructor(
    private cd: ChangeDetectorRef,
    private fcmService: FcmService,
    private service: AuthService
  ) {}

  getTimeAgo(timestamp: string): string {
    return moment(timestamp).fromNow(); // e.g., "2 hours ago"
  }

  ngOnInit() {
    this.loadNotifications();

    // this.fcmService.getCurrentToken().subscribe((token) => {
    //   if (token) {
    //     console.log("Current token:", token);
    //   }
    // });

    this.fcmService.currentMessage.subscribe((message) => {
      if (message) {
        this.notificationBuffer.push({
          title: message.notification?.title || "Untitled Notification",
          message: message.notification?.body || "No content available",
          unread: message.data?.unread === 'true',
          createdAt: new Date().toISOString(),
        });
    
        if (!this.notificationTimeout) {
          this.notificationTimeout = setTimeout(() => {
            this.processBufferedNotifications();
          }, 500); // Process notifications every 500ms
        }
      }
    });
  }

  private processBufferedNotifications() {
    this.notifications = [...this.notificationBuffer, ...this.notifications];
    this.unreadCount = this.notifications.filter((n) => n.unread).length;
    this.applyFilter();
    this.cd.markForCheck();
  
    // Clear the buffer and timeout
    this.notificationBuffer = [];
    this.notificationTimeout = null;
  }

  loadNotifications() {
    this.service.getNotifications().subscribe(
      (data: any[]) => {
        this.notifications = data;
        this.updateUnreadCount();
        this.applyFilter();
        this.isLoading = false; // Stop loading after fetching data
        this.cd.markForCheck();
      },
      (error) => {
        console.error("Error loading notifications:", error);
        this.isLoading = false; // Stop loading even on error
      }
    );
  }

  updateUnreadCount() {
    this.unreadCount = this.notifications.filter(
      (notification) => notification.unread
    ).length;
  }

  markNotificationAsRead(notification: any) {
    if (notification.unread) {
      this.service.markAsRead(notification._id).subscribe(
        () => {
          notification.unread = false;
          // this.isLoading = true; // Indicate loading state
          this.loadNotifications();
          // this.updateUnreadCount();
          // this.applyFilter();
        },
        (error) => console.error("Error marking notification as read:", error)
      );
    }
  }

  toggleMessageVisibility(notification: any, event: Event) {
    event.stopPropagation();
    notification.showMessage = !notification.showMessage;
    if (notification.showMessage && notification.unread) {
      this.markNotificationAsRead(notification);
    }
  }

  setFilter(type: string, event: Event): void {
    event.stopPropagation();
    this.filterType = type;

    if (type === "all") {
      this.filteredNotifications = this.notifications;
    } else if (type === "unread") {
      this.filteredNotifications = this.notifications.filter((n) => n.unread);
    }
  }

  closeMenu() {
    this.menuTrigger.closeMenu();
  }

  markAllAsRead(event: Event) {
    event.stopPropagation();
    this.service.markAllAsRead().subscribe(
      () => {
        this.notifications.forEach(
          (notification) => (notification.unread = false)
        );
        // this.updateUnreadCount();
        this.isLoading = true; // Indicate loading state
        this.loadNotifications();
      },
      (error) =>
        console.error("Error marking all notifications as read:", error)
    );
  }

  markAllDelete(event: Event) {
    event.stopPropagation();
    this.service.markAllAsDelete().subscribe(
      () => {
        this.notifications.forEach(
          (notification) => (notification.unread = false)
        );
        this.isLoading = true; // Indicate loading state
        this.loadNotifications();
      },
      (error) =>
        console.error("Error marking all notifications as read:", error)
    );
  }

  onOpenMenu(event: MouseEvent): void {
    event.stopPropagation();
    this.isLoading = true; // Indicate loading state
    this.loadNotifications();
    this.menuTrigger.openMenu();
  }

  applyFilter() {
    if (this.filterType === "all") {
      this.filteredNotifications = this.notifications;
    } else if (this.filterType === "unread") {
      this.filteredNotifications = this.notifications.filter(
        (notification) => notification.unread
      );
    }
  }
}
