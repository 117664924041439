import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

import { ToastrService } from "ngx-toastr";

import { environment } from "../../../environments/environment";
import { AuthData } from "./auth-data.model";
import { FcmService } from "src/app/fcm.service";
const BACKEND_URL = environment.apiUrl + "/admin/";

@Injectable({ providedIn: "root" })
export class AuthService {
  private fcmToken: string;
  private isAuthenticated = false;
  private token: string;
  private tokenTimer: any;
  private userId: string;
  private username: string;
  private roleName: string;
  private permission: any;
  private authStatusListener = new Subject<boolean>();
  isLoading: boolean;
  public isLoadingsubject = new Subject<boolean>();
  // private forgotStatusListener = new Subject<boolean>();
  constructor(
    private http: HttpClient,
    private router: Router,
    private toastr: ToastrService,
    private fcmService: FcmService
  ) {}

  getToken() {
    return this.token;
  }

  getIsAuth() {
    return this.isAuthenticated;
  }

  getUserId() {
    return this.userId;
  }

  getuserName() {
    return this.username;
  }

  getRoleName() {
    return this.roleName;
  }

  getAuthStatusListener() {
    return this.authStatusListener.asObservable();
  }

  getNotifications() {
    return this.http.get(`${BACKEND_URL}notifications`);
  }

  markAsRead(notificationId: string) {
    return this.http.put(`${BACKEND_URL}notifications/${notificationId}/mark-as-read`, {});
  }

  markAllAsRead() {
    return this.http.put(`${BACKEND_URL}notifications/mark-all-as-read`, {});
  }

  markAllAsDelete() {
    return this.http.delete(`${BACKEND_URL}delete_notifications`, {});
  }

  // login(email: string, password: string) {
  //   const authData: AuthData = { email: email, password: password };
  //   this.http
  //     .post<{
  // token: string;
  // expiresIn: number;
  // userId: string;
  // username: string;
  // role: string;
  // permission: any;
  //     }>(BACKEND_URL + "login", authData)
  //     .subscribe({
  //       next: (response) => {
  //         const token = response.token;
  //         this.token = token;
  //         if (token) {
  //           localStorage.setItem(
  //             "permission",
  //             JSON.stringify(response.permission)
  //           );
  //           const expiresInDuration = response.expiresIn;
  //           this.setAuthTimer(expiresInDuration);
  //           this.isAuthenticated = true;
  //           this.userId = response.userId;
  //           this.username = response.username;
  //           this.roleName = response.role;
  //           this.authStatusListener.next(true);
  //           const now = new Date();
  //           const expirationDate = new Date(
  //             now.getTime() + expiresInDuration * 1000
  //           );
  // this.saveAuthData(
  //   token,
  //   expirationDate,
  //   this.userId,
  //   this.username,
  //   this.roleName
  // );
  //           this.toastr.success("Login successful", response.username.toUpperCase(), {
  //             timeOut: 1500,
  //             closeButton: true,
  //           });
  //           this.router.navigate(["dashboard"]);
  //         }
  //       },
  //       error: (error) => {
  //         this.authStatusListener.next(false);
  //       },
  //       complete: () => {
  //         console.log("Login request completed");
  //       },
  //     });
  // }

  login(email: string, password: string) {
    const authData: AuthData = { email: email, password: password };

    this.fcmService
      .getToken()
      .then((fcmToken) => {
        console.log(fcmToken, "token");
        const loginData = { ...authData, fcmToken: fcmToken };

        this.http
          .post<{
            token: string;
            expiresIn: number;
            userId: string;
            username: string;
            role: string;
            permission: any;
          }>(BACKEND_URL + "login", loginData)
          .subscribe({
            next: (response) => {
              const token = response.token;
              this.token = token;
              if (token) {
                localStorage.setItem(
                  "permission",
                  JSON.stringify(response.permission)
                );
                const expiresInDuration = response.expiresIn;
                this.setAuthTimer(expiresInDuration);
                this.isAuthenticated = true;
                this.userId = response.userId;
                this.username = response.username;
                this.roleName = response.role;
                this.authStatusListener.next(true);
                const now = new Date();
                const expirationDate = new Date(
                  now.getTime() + expiresInDuration * 1000
                );
                this.saveAuthData(
                  token,
                  expirationDate,
                  this.userId,
                  this.username,
                  this.roleName
                );
                this.toastr.success("Login successful", response.username, {
                  timeOut: 1500,
                  closeButton: true,
                });

                this.router.navigate(["dashboard"]);
              }
            },
            error: (error) => {
              this.authStatusListener.next(false);
            },
            complete: () => {
              console.log("Login request completed");
            },
          });
      })
      .catch((error) => {
        console.error("Error getting FCM token:", error);
      });
  }

  add(name: any, type: any, encounterId: any,applicantDigitalService:any, signature: string) {
    let payload = {
      name: name,
      type: type,
      encounterId: encounterId,
      sign: signature,
      applicantDigitalService:applicantDigitalService
    };
    return this.http.post(BACKEND_URL + "acceptDigitalService/", payload);
  }

  autoAuthUser() {
    console.log("usfssf");
    const authInformation = this.getAuthData();
    if (!authInformation) {
      return;
    }
    const now = new Date();
    const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
    if (expiresIn > 0) {
      this.token = authInformation.token;
      this.isAuthenticated = true;
      this.userId = authInformation.userId;
      this.username = authInformation.username;
      this.roleName = authInformation.roleName;
      this.setAuthTimer(expiresIn / 1000);
      this.authStatusListener.next(true);

      this.fetchPermissions();
    }
  }

  fetchPermissions() {
    if (!this.userId) {
      console.error("User ID is not set, cannot fetch permissions.");
      return;
    }
    this.http
      .get<{ permissions: any }>(BACKEND_URL + "permissions/" + this.userId)
      .subscribe({
        next: (response) => {
          localStorage.setItem(
            "permission",
            JSON.stringify(response.permissions)
          );
        },
        error: (error) => {
          console.error("Error fetching permissions:", error);
        },
      });
  }

  logout() {
    this.token = null;
    this.isAuthenticated = false;
    this.authStatusListener.next(false);
    this.userId = null;
    (this.username = null), (this.roleName = null);
    clearTimeout(this.tokenTimer);
    this.clearAuthData();
    this.router.navigate(["/"]);
    setTimeout(() => {
      this.toastr.info("is logged out", "", {
        timeOut: 2000,
      });
    }, 500);
  }

  private setAuthTimer(duration: number) {
    console.log("Setting timer: " + duration);
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  private saveAuthData(
    token: string,
    expirationDate: Date,
    userId: string,
    username: string,
    roleName: string
  ) {
    localStorage.setItem("token", token);
    localStorage.setItem("expiration", expirationDate.toISOString());
    localStorage.setItem("userId", userId);
    localStorage.setItem("username", username);
    localStorage.setItem("roleName", roleName);
  }
  private clearAuthData() {
    localStorage.removeItem("token");
    localStorage.removeItem("expiration");
    localStorage.removeItem("userId");
    localStorage.removeItem("username");
    localStorage.removeItem("roleName");
    localStorage.removeItem("permission");
  }

  private getAuthData() {
    const token = localStorage.getItem("token");
    const expirationDate = localStorage.getItem("expiration");
    const userId = localStorage.getItem("userId");
    const username = localStorage.getItem("username");
    const roleName = localStorage.getItem("roleName");
    if (!token || !expirationDate) {
      return;
    }
    return {
      token: token,
      expirationDate: new Date(expirationDate),
      userId: userId,
      username: username,
      roleName: roleName,
    };
  }

  forgotPassword(email: string) {
    const Email = { email: email };
    this.http
      .post<{ message: string; status: string }>(
        BACKEND_URL + "forgotPassword",
        Email
      )
      .subscribe({
        next: (response) => {
          if (response.status === "success") {
            this.isLoadingsubject.next(true);
          }
          setTimeout(() => {
            this.toastr.info(
              "Successfully sent password reset link to your email 👍.",
              "Reset Link",
              {
                timeOut: 6000,
                closeButton: true,
              }
            );
          }, 400);
        },
        error: (error) => {
          this.authStatusListener.next(false);
          this.toastr.error(
            "Failed to send password reset link",
            error.message,
            {
              timeOut: 3000,
              closeButton: true,
            }
          );
        },
        complete: () => {
          console.log("Forgot password request completed");
        },
      });
  }

  resetPassword(password: string, passwordConfirm: string, token: string) {
    const resetData = { password: password, passwordConfirm: passwordConfirm };
    this.http
      .patch<{ data }>(BACKEND_URL + "resetPassword" + "/" + token, resetData)
      .subscribe((responce) => {
        this.toastr.success(
          "Your password is successfully reset👍.",
          "Successfully",
          {
            timeOut: 4000,
            closeButton: true,
          }
        );
        setTimeout(() => {
          this.router.navigate(["login"]);
        }, 300);
      });
  }
}
