import {
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
} from "@angular/core";
import { LayoutService } from "../../services/layout.service";
import icBookmarks from "@iconify/icons-ic/twotone-bookmarks";
import emojioneUS from "@iconify/icons-emojione/flag-for-flag-united-states";
import emojioneDE from "@iconify/icons-emojione/flag-for-flag-germany";
import icMenu from "@iconify/icons-ic/twotone-menu";
import { ConfigService } from "../../services/config.service";
import { map } from "rxjs/operators";
import icPersonAdd from "@iconify/icons-ic/twotone-person-add";
import icAssignmentTurnedIn from "@iconify/icons-ic/twotone-assignment-turned-in";
import icBallot from "@iconify/icons-ic/twotone-ballot";
import icDescription from "@iconify/icons-ic/twotone-description";
import icAssignment from "@iconify/icons-ic/twotone-assignment";
import icReceipt from "@iconify/icons-ic/twotone-receipt";
import icDoneAll from "@iconify/icons-ic/twotone-done-all";
import { NavigationService } from "../../services/navigation.service";
import icArrowDropDown from "@iconify/icons-ic/twotone-arrow-drop-down";
import { PopoverService } from "../../components/popover/popover.service";
// import { MegaMenuComponent } from "../../components/mega-menu_e/mega-menu.component";
import icSearch from "@iconify/icons-ic/twotone-search";
import {
  trigger,
  state,
  style,
  animate,
  transition,
} from "@angular/animations";
import { GoogleAuthService } from "./google.service";
@Component({
  selector: "vex-toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.scss"],
  animations: [
    trigger("expandCollapse", [
      state(
        "collapsed",
        style({
          width: "0",
          opacity: "0",
          overflow: "hidden",
        })
      ),
      state(
        "expanded",
        style({
          width: "*",
          opacity: "1",
        })
      ),
      transition("collapsed <=> expanded", [animate("300ms ease-in-out")]),
    ]),
  ],
})
export class ToolbarComponent implements OnInit {
  @Input() mobileQuery: boolean;

  @Input()
  @HostBinding("class.shadow-b")
  hasShadow: boolean;

  navigationItems = this.navigationService.items;

  isHorizontalLayout$ = this.configService.config$.pipe(
    map((config) => config.layout === "horizontal")
  );
  isVerticalLayout$ = this.configService.config$.pipe(
    map((config) => config.layout === "vertical")
  );
  isNavbarInToolbar$ = this.configService.config$.pipe(
    map((config) => config.navbar.position === "in-toolbar")
  );
  isNavbarBelowToolbar$ = this.configService.config$.pipe(
    map((config) => config.navbar.position === "below-toolbar")
  );

  icSearch = icSearch;
  icBookmarks = icBookmarks;
  emojioneUS = emojioneUS;
  emojioneDE = emojioneDE;
  icMenu = icMenu;
  icPersonAdd = icPersonAdd;
  icAssignmentTurnedIn = icAssignmentTurnedIn;
  icBallot = icBallot;
  icDescription = icDescription;
  icAssignment = icAssignment;
  icReceipt = icReceipt;
  icDoneAll = icDoneAll;
  icArrowDropDown = icArrowDropDown;

  constructor(
    private layoutService: LayoutService,
    private configService: ConfigService,
    private navigationService: NavigationService,
    private popoverService: PopoverService,
    private googleAuthService: GoogleAuthService
  ) {}

  ngOnInit() {}

  handleGoogleDriveAuth() {
    const authUrl = this.googleAuthService.getAuthorizationUrl();
    window.location.href = authUrl;
  }
  openQuickpanel() {
    this.layoutService.openQuickpanel();
  }

  openSidenav() {
    this.layoutService.openSidenav();
  }

  redirectTo(url: string): void {
    window.open(url, '_blank'); 
  }
  
  

  // openMegaMenu(origin: ElementRef | HTMLElement) {
  //   this.popoverService.open({
  //     content: MegaMenuComponent,
  //     origin,
  //     position: [
  //       {
  //         originX: "start",
  //         originY: "bottom",
  //         overlayX: "start",
  //         overlayY: "top",
  //       },
  //       {
  //         originX: "end",
  //         originY: "bottom",
  //         overlayX: "end",
  //         overlayY: "top",
  //       },
  //     ],
  //   });
  // }

  
  openSearch() {
    this.layoutService.openSearch();
  }

  isExpanded = false;

  toggleSearch() {
    this.isExpanded = !this.isExpanded;
  }
  search(term: string) {
    console.log("Searching for:", term);
  }
}
